import 'whatwg-fetch';
import 'react-app-polyfill/stable';
import 'fontsource-roboto';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import { apolloClient } from '../apollo';
import { reduxStore } from '../redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import AdminMenu from './Admin/AdminMenu';
import PantryList from './Admin/PantryList';
import ReservationList from './Admin/ReservationList';
import MapSite from './MapSite';

export const store = reduxStore({});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MapSite/>
    )
  },
  {
    path: "/volunteer",
    element: (
      <MapSite/>
    )
  },
  {
    path: "/admin",
    element: (
      <AdminMenu/>
    ),
    children: [
      {
        path: "/admin/pantryList",
        element: <PantryList />,
      },
      {
        path: "/admin/reservationList",
        element: <ReservationList />,
      },
    ],
  },
  { path: "*", element: <h2 style={{margin: 50}}> NOT FOUND </h2> },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: '#76b900',
    },
    secondary: {
      main: '#442c79',
    },
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth: '650px !important',
        '@media (max-width: 663.95px)': {
          maxWidth: 'calc(100% - 24px) !important',
          margin: '12px',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontWeight: 500,
        fontSize: 16,
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 6,
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#f44336',
        '&:hover': {
          backgroundColor: 'rgb(244, 67, 54, 0.04)',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: '#FFF',
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
    MuiBackdrop: {
      invisible: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    MuiFab: {
      sizeSmall: {
        padding: '0 10px !important',
      },
    },
  } as any,
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>      
      </Provider>
    </ApolloProvider>
  );
};

export default App;
