
import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import RegistrationDialog from '../RegistrationDialog';
import Auth from '../Auth';


const AdminMenu = () => {
    const [tabValue, setTabValue] = useState(window.location.href.includes('pantryList')? 0:1);
    const [authPass, setAuthPass] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
        if (newValue === 0){
            navigate('/admin/pantryList')
        } else if (newValue === 1){
            navigate('/admin/reservationList')
        }
    };

    return (
        <div>
            {authPass && 
            <>
                <h1 style={{marginInline:20}}>Admin Portal</h1>
                <Box>
                    {
                    // eslint-disable-next-line 
                    // @ts-ignore
                    }
                    <Tabs variant="fullWidth" value={tabValue} onChange={(event, value) => handleChange(event, value)} aria-label="basic tabs">
                        <Tab label="Pantry List" />
                        <Tab label="Reservation List"  />
                    </Tabs>
                </Box>
                <Outlet></Outlet>
            </>}
            <RegistrationDialog
                open={!authPass}
                onClose={() => {
                    if(!authPass){
                        navigate('/')
                    }
                }}
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Auth
                    onClose={() => {navigate('/')}}
                    setAuthPass={setAuthPass}
                />
            </RegistrationDialog>
        </div>
    );
};

export default AdminMenu;
