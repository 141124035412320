class Dictionary {
  public values: any;
  public options: any;

  constructor(values: any) {
    this.values = values;
    this.options = values.map((value: any) => ({
      value,
      label: value,
    }));
  }
}

const contactMethod = ['E-Mail', 'Phone'];

export default new Dictionary(contactMethod);
