import gql from 'graphql-tag';

export default gql`
  query {
    pantrySites {
      preRegistration
      pantries {
        Pantry
        Name
        Address1
        Address2
        Zip
        Phone
        Contact
        Referals
        Allowed
        Lat
        Long
        availability
        exceededLimitWarning
        SYHFlag
        Year
        ActiveAgencyFlag
        ExternalAllowed
        times {
          sDate
          sTime
        }
      }
    }
  }
`;
