import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

import {
  AVAILABLE_COLOR,
  SUPPORT_ENGLISH,
  SUPPORT_SPANISH,
  UNAVAILABLE_COLOR,
} from '../config';

const InfoDialog = ({ open, setShowInfo }: any) => {
  return (
    <Dialog
      open={open}
      onClose={() => setShowInfo(false)}
      scroll="body"
      disableRestoreFocus
      maxWidth="md"
    >
      <DialogTitle
        className="notranslate"
        disableTypography
        style={{ textAlign: 'center', fontSize: 24 }}
      >
        Share Your Holidays Registration
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography variant="h6">Map Information:</Typography>
          <div className="info-dialog-section">
            <div className="info-icon-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth="1%"
                stroke="#031A06"
                focusable="false"
                viewBox="0 0 24 24"
                width="36"
                height="36"
              >
                <path
                  fill={AVAILABLE_COLOR}
                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                />
              </svg>
            </div>
            &nbsp;
            <Typography variant="body1">
              Indicates a partner agency that is accepting online registrations
            </Typography>
          </div>
          <div className="info-dialog-section">
            <div className="info-icon-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth="1%"
                stroke="#031A06"
                focusable="false"
                viewBox="0 0 24 24"
                width="36"
                height="36"
              >
                <path
                  fill={UNAVAILABLE_COLOR}
                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                />
              </svg>
            </div>
            &nbsp;
            <Typography variant="body1">
              Indicates a partner agency that is NOT currently accepting online
              registrations
            </Typography>
          </div>
          <div className="info-dialog-section">
            <div className="info-icon-container">
              <img
                alt="cluster-icon"
                src={`${process.env.PUBLIC_URL}/images/m1.png`}
                width="36"
                height="36"
              />
            </div>
            &nbsp;
            <Typography variant="body1">
              Indicates a cluster of partner agencies (number inside is how many
              partner agencies it contains)
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="h6">How to Register:</Typography>
          <ol className="info-dialog-register-list">
            <li>
              <Typography variant="body1">
                <b>1.</b>&nbsp;Select a partner agency that is accepting
                registrations
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <b>2.</b>&nbsp;Click the “Register” button
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <b>3.</b>&nbsp;Fill out the registration form with your
                information and click “Submit”
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <b>4.</b>&nbsp;If the registration was successful you will see a
                confirmation message, and a confirmation email will be sent to
                the address entered on the form
              </Typography>
            </li>
          </ol>
        </div>
        <div>
          <Typography variant="h6">Navigating Site:</Typography>
          <div className="info-dialog-qa-wrapper">
            <Typography variant="body1">
              <b className="notranslate">Q:</b>&nbsp;Why is the search not
              working as expected?
            </Typography>
            <Typography variant="body1">
              <b className="notranslate">A:</b>&nbsp;Use the search input to
              filter partner agencies by name or address, the map will
              automatically update as you type.
            </Typography>
          </div>
          <div className="info-dialog-qa-wrapper">
            <Typography variant="body1">
              <b className="notranslate">Q:</b>&nbsp;Why is the form not
              submitting?
            </Typography>
            <Typography variant="body1">
              <b className="notranslate">A:</b>&nbsp;Make sure all required
              fields have been filled out; you will see a warning after
              attempting to submit if information is missing.
            </Typography>
          </div>
          <div className="info-dialog-qa-wrapper">
            <Typography variant="body1">
              <b className="notranslate">Q:</b>&nbsp;Why is the form showing an
              error?
            </Typography>
            <Typography variant="body1">
              <b className="notranslate">A:</b>&nbsp;Double check the entered
              information or reload the page and try submitting the form again.
            </Typography>
          </div>
          <div className="info-dialog-qa-wrapper">
            <Typography variant="body1">
              <b className="notranslate">Q:</b>&nbsp;Why is my partner agency
              not showing up on the map?
            </Typography>
            <Typography variant="body1">
              <b className="notranslate">A:</b>&nbsp;Not all of our partners
              participate in Share Your Holidays. Please select another agency
              that&apos;s close to your home even though you may not be familiar
              with them.
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="h6">
            Need Assistance? Please contact Houston Food Bank:
          </Typography>
          <div className="info-dialog-contact-wrapper">
            <Typography variant="body1">
              English:&nbsp;
              <a href={`tel:${SUPPORT_ENGLISH}`}>{SUPPORT_ENGLISH}</a>
            </Typography>
            <Typography variant="body1">
              Spanish:&nbsp;
              <a href={`tel:${SUPPORT_SPANISH}`}>{SUPPORT_SPANISH}</a>
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowInfo(false)}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
