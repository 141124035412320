import { compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';

import createRootReducer from './reducers';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const history = createBrowserHistory();

const reduxStore = (preloadedState: any) => {
  const store = createStore(
    createRootReducer(),
    preloadedState,
    composeEnhancers(),
  );

  return store;
};

export { reduxStore, history };
