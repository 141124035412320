import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  Backdrop
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useMutation } from 'react-apollo';
import { SubmissionError, Field, Form, reduxForm } from 'redux-form';
import getErrorMessage from '../helpers/getErrorMessage';
import TextField from './form-fields/TextField';
import sendPass from '../apollo/mutations/sendPass';
import sha256 from 'crypto-js/sha256';

const AuthForm = reduxForm({ form: 'authForm' })((props: any) => {
  const {
    handleSubmit,
    submitting,
    onClose,
    registerFunction,
    dirty,
    submitFailed,
    valid,
    error
  } = props;

  return (
    <>
      <Backdrop invisible open={submitting} style={{ zIndex: 99 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Form autoComplete="off" onSubmit={handleSubmit(registerFunction)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="pass"
                component={TextField}
                label="Password"
                type="password"
                required
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12}>
              {
                <>
                  {!error && dirty && !valid && submitFailed && (
                    <Alert severity="warning">
                      {`Complete all required fields`}
                    </Alert>
                  )}
                  {error && (
                    <Alert severity="error">
                      <div>{error}</div>
                    </Alert>
                  )}
                </>
              }
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            <Button
              onClick={onClose}
              color="primary"
              disabled={submitting}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </>
  );
});

const Auth = (props: any) => {
  const [registerMutation] = useMutation(sendPass, {
    onCompleted({ sendPass }) {
      if (sendPass) {
        props.setAuthPass(true);
      } else {
        throw new SubmissionError({
          _error: 'Submission Error, please try again.',
        });
      }
    },
    onError(error) {
      throw new SubmissionError({ _error: getErrorMessage(error) });
    }
  });

  const registerFunction = (values: any) => {
    return registerMutation({
      variables: {
        pass: sha256(values.pass).toString(),
      },
    });
  };

  return (
    <AuthForm
      {...props}
      registerFunction={registerFunction}
    />
  );
};

export default Auth;
