/* eslint-disable */
import toLower from 'lodash/toLower';

export const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;
export const NODE_ENV = process.env.NODE_ENV;
export const SUPPORT_ENGLISH = process.env.REACT_APP_SUPPORT_ENGLISH;
export const SUPPORT_SPANISH = process.env.REACT_APP_SUPPORT_SPANISH;
export const AVAILABLE_COLOR = process.env.REACT_APP_AVAILABLE_COLOR;
export const UNAVAILABLE_COLOR = process.env.REACT_APP_UNAVAILABLE_COLOR;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
