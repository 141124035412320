import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';

const RadioButtonGroup = ({
  input,
  label,
  options,
  helperText,
  meta: { touched, error },
  ...rest
}: any) => (
  <FormControl error={Boolean(touched && error)}>
    <FormLabel color="secondary">{label}</FormLabel>
    <RadioGroup
      {...input}
      {...rest}
      value={input.value}
      onChange={(event: any, value: any) => input.onChange(value)}
    >
      {options.map((option: any) => {
        return (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
          />
        );
      })}
    </RadioGroup>
    <FormHelperText>
      {touched && Boolean(error) ? error : helperText}
    </FormHelperText>
  </FormControl>
);

export default RadioButtonGroup;
