import { Box, Button, Dialog, DialogActions, DialogTitle, LinearProgress } from '@material-ui/core';

type ConfirmDialogProps = {
    confirm: any;
    close: any;
    children: any;
    loading: any;
};

export default function ConfirmDialog(props: ConfirmDialogProps) {

    return (
        <Dialog open={true} onClose={props.close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.children}</DialogTitle>
            { !props.loading.loading ?<DialogActions>
                <Button onClick={props.close} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.confirm} color="primary" autoFocus>
                    Accept
                </Button>
            </DialogActions>:
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>}
        </Dialog>
    );
}
