import {
  Fab,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import React from 'react';

const SearchInput = ({
  resetVisible,
  resetMap,
  resetSearch,
}: any) => {
  return (
    <>
      <Paper
        component="form"
        className="search-input-wrapper"
        onSubmit={(e) => e.preventDefault()}
      >
        <InputBase
          autoFocus
          className="search-input"
          id="search-input-base"
          autoComplete="off"
        />
        <Tooltip enterTouchDelay={100} title="Clear" arrow aria-label="clear">
          <IconButton aria-label="clear" onClick={resetSearch}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Zoom in={resetVisible}>
          <Fab
            disableRipple
            variant="extended"
            size="small"
            aria-label="reset"
            className="reset-button"
            onClick={resetMap}
          >
            <ReplayIcon style={{ marginRight: 8 }} />
            Reset
          </Fab>
        </Zoom>
      </Paper>
    </>
  );
};

export default SearchInput;
