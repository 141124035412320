class Dictionary {
  public values: any;
  public options: any;

  constructor(values: any) {
    this.values = values;
    this.options = values.map((value: any) => ({
      value,
      label: value,
    }));
  }
}

const idTypes = ['Texas ID', 'Drivers License', 'Utility Bill', 'Other'];

export default new Dictionary(idTypes);
