import MaterialTextField from '@material-ui/core/TextField';
import React from 'react';

const TextField = ({
  input,
  label,
  helperText,
  placeholder = '',
  meta: { touched, error },
  ...custom
}: any) => (
  <MaterialTextField
    size="small"
    variant="outlined"
    placeholder={placeholder}
    label={label}
    error={touched && Boolean(error)}
    helperText={touched && Boolean(error) ? error : helperText}
    {...input}
    {...custom}
  />
);

export default TextField;
