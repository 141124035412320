import {
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DropDownMenu = ({
  input,
  label: formLabel,
  helperText,
  required,
  options,
  meta: { touched, error },
  ...custom
}: any) => {
  return (
    <FormControl
      {...custom}
      variant="outlined"
      error={Boolean(touched && error)}
      size="small"
    >
      <InputLabel required={required} error={Boolean(touched && error)}>
        {formLabel}
      </InputLabel>
      <Select native onChange={input.onChange} className="notranslate">
        <option aria-label="None" value="" />
        {options.map(({ label, value }: any) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      {(helperText || (touched && error)) && (
        <FormHelperText error={Boolean(touched && error)}>
          {touched && error ? error : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

DropDownMenu.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object.isRequired,
};

DropDownMenu.defaultProps = {
  helperText: undefined,
  required: false,
};

export default DropDownMenu;
