
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { adminPantrySites } from '../../apollo';
import { Box, FormControl, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';


const PantryList = () => {
  const { data, error, loading } = useQuery(adminPantrySites);
  const [order, setOrder] = React.useState('');
  const [visibleRows, setVisibleRows] = React.useState([]);

 

  useEffect(() => {
    
    if (!loading)
      setVisibleRows(data.pantrySites.pantries.filter((el:any) => el.Name.toLowerCase().includes(order.toLowerCase())))},
    [order, data]
  );

  const columns: GridColDef[] = [
    { field: 'Pantry', headerName: 'Pantry', width: 130 },
    { field: 'Name', headerName: 'Name', width: 130 }, 
    { field: 'Address1', headerName: 'Address 1', width: 130 },
    { field: 'Address2', headerName: 'Address 2', width: 130 }, 
    { field: 'Zip', headerName: 'Zip', width: 130 },
    { field: 'Phone', headerName: 'Phone', width: 130 }, 
    { field: 'Contact', headerName: 'Contact', width: 130 },
    { field: 'Referals', headerName: 'Referals', width: 130 }, 
    { field: 'Allowed', headerName: 'Allowed', width: 130 },
    { field: 'SYHFlag', headerName: 'SYHFlag', width: 130 }, 
    { field: 'Year', headerName: 'Year', width: 130 },
    { field: 'ActiveAgencyFlag', headerName: 'Active Agency Flag', width: 130 }, 
    { field: 'Lat', headerName: 'Lat', width: 130 },
    { field: 'Long', headerName: 'Long', width: 130 }, 
    { field: 'ExternalAllowed', headerName: 'External Allowed', width: 130 }, 
  ];

  return ( !loading ? 
        <Box>
          <Box padding={2}><FormControl fullWidth><TextField onChange={(e) => setOrder(e.target.value)} id="outlined-basic" label="Search" variant="outlined" /></FormControl></Box>
          <DataGrid
            checkboxSelection={false}
            getRowId={(row) => row.Pantry}
            rows={visibleRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[50, 100]}
          />
        </Box>:
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
  );
};

export default PantryList;
