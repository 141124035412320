import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_SITE_KEY } from '../config';

const Captcha = (props: any) => (
  <React.Fragment>
    <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={props.input.onChange} />
  </React.Fragment>
);

export default Captcha;
