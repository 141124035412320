import { onError } from 'apollo-link-error';

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { message } = error;

      console.warn(`[GraphQL error]: Message: ${message}`);
    }
  }
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
});

export default onErrorLink;
