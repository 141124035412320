import { Dialog } from '@material-ui/core';
import React from 'react';

const RegistrationDialog = (props: any) => (
  <Dialog
    {...props}
    transitionDuration={0}
    maxWidth="md"
    scroll="body"
    disableRestoreFocus
  >
    {props.children}
  </Dialog>
);

export default RegistrationDialog;
